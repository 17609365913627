@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import 'react-toastify/dist/ReactToastify.css';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input {
  font-size: 16px !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
}

.initSvgClass {
  position: fixed;
  width: 0;
  height: 0;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}
