@import '../../styles/variables';
@import '../../styles/mixins';

.accountPage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 32px 28px;
}

.accountLink {
  text-decoration: none !important;
}

.accountDataHolder {
  display: flex;
}

.account {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &Text {
    display: flex;
    align-items: center;
    color: #f5f5f7;
    font-size: 14px;
    line-height: 16px;

    > *:first-of-type {
      text-decoration: none;
    }
  }
}

.img {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.drawerPaper {
  z-index: 250;
  left: $aside-width;
  display: flex;
  width: $aside-width;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 35px 24px;
  border-left: 2px solid #212831;
  background: #1b222c;
  overflow-x: hidden;

  @include mobile {
    top: $top-bar-mobile;
    left: 0;
    width: 100vw;
    height: calc(100vh - $top-bar-mobile);
    padding: 16px;
    border-top: 2px solid #212831;
  }
}

.drawerModalRoot {
  position: relative;
  z-index: 300;

  @include mobile {
    z-index: 3000 !important;
  }
}

.drawerBackdrop {
  background-color: transparent !important;
}

.accountTitle {
  margin-left: 14px;
  color: #f5f5f7;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  @include mobile {
    margin-left: 0;
  }
}

.addressButton {
  margin-left: 14px;
  font-size: 16px;
  line-height: 20px;

  @include mobile {
    margin-left: 0;
  }
}

.copyIcon {
  margin-left: 24px;

  @include mobile {
    margin-left: 4px;
  }
}

.accountActionsHolder {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  gap: 28px;
  line-height: 21px;

  @include mobile {
    gap: 20px;
  }
}

.accountActionText {
  color: #6b798f;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 21px;
}

.accountAction {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 8px 14px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;

  @include mobile {
    height: 48px;
  }

  &:hover {
    background: #252b35;

    .accountActionText {
      @include text-gradient(linear-gradient(90deg, #1768e5 0%, #00f0ff 100%));
    }
  }

  &:hover,
  &:focus {
    background: #2e353d;

    .accountActionText {
      @include text-gradient(linear-gradient(90deg, #1768e5 50.44%, #00f0ff 129.31%));
    }
  }
}

.icon {
  margin-right: 18px;

  @include mobile {
    margin-bottom: 2px;
  }
}

@include icon-colors('.accountAction');

.supportLink {
  margin-top: auto;
  margin-left: auto;
}

@include icon-colors('.supportLink');

.importAccountInput {
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
}
