@import '../../styles/variables';
@import '../../styles/mixins';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  margin: 0 28px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.textOpacity {
  color: #f5f5f7;
  opacity: 0.24;
}

.paginationListTop {
  display: none;

  @include mobile {
    display: flex;
  }
}
