@import '../../../styles/variables';
@import '../../../styles/mixins';

.breadcrumbs {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;

  @include mobile {
    margin-bottom: 28px;
  }
}

.breadcrumbsHolder {
  display: flex;
  justify-content: space-evenly;
}

.breadcrumbsHolderBordered {
  padding-bottom: 24px;
  border-bottom: 2px solid #2e3642;

  @include mobile {
    padding-bottom: 20px;
  }
}

.breadcrumbLabel {
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;

  @include mobile {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: $unit * 8;
  color: #6b798f;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 24px;
  text-align: center;

  @include mobile {
    margin-right: unset !important;
    font-size: 16px;
    white-space: nowrap;
  }
}

.breadcrumbIcon {
  margin: 0 $unit * 3;
  margin-top: $unit * 0.5;
}

.selectedBreadcrumb {
  color: #f5f5f7;

  .breadcrumbLabel {
    @media screen and (max-width: $tablet) {
      display: block;
    }
  }
}

.selectedBreadcrumbIcon {
  fill: #f5f5f7;
}

.tabBreadcrumb.selectedBreadcrumb {
  border-bottom: 2px solid #169888;
}

.tabsBreadcrumbsHolder {
  display: flex;
  height: $unit * 8.5;
  align-items: center;
  align-self: flex-start;
  font-weight: 500;

  &_button {
    border-radius: $unit * 2;
    background: #f7f7fa;

    .breadcrumb {
      max-width: 250px;
      border: 0.5px solid transparent;
    }

    .selectedBreadcrumb {
      border: 0.5px solid rgb(0 0 0 / 4%);
      border-radius: 8px;
      border-bottom: unset !important;
      margin-bottom: $unit * 0.5;
      box-shadow:
        0 3px 8px rgb(0 0 0 / 12%),
        0 3px 1px rgb(0 0 0 / 4%);
    }
  }

  .breadcrumb {
    height: $unit * 8.5;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
    color: #000;
    cursor: pointer;

    .breadcrumbIcon {
      display: none;
    }
  }

  .selectedBreadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #87ceebff !important;
    border-image: $the-power-gradient;
    border-image-slice: 1 !important;
  }
}

.breadcrumbIndex {
  margin-right: $unit;
}
