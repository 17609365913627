@import '../../../styles/mixins';

.nftCard {
  padding: 60px 136px;

  @include mobile {
    overflow: hidden;
    width: 100vw;
    padding: 20px 10px;
  }
}

.nftMainPart {
  position: relative;
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

.nftOwnersInfo {
  display: flex;
  flex-direction: column;
  margin-left: 34px;
  gap: 20px;

  @include mobile {
    margin-top: 32px;
    margin-left: 0;
  }
}

.nftImg {
  position: relative;
  width: 280px;
  height: 280px;
  flex-shrink: 0;
  border-radius: 5px 5px 0 0;

  @include mobile {
    width: calc(100vw - 20px);
    height: calc(100vw - 20px);
    border-radius: 5px;
    background-size: cover;
  }
}

.nftOwnersInfoPart {
  display: flex;
  flex-direction: row;
}

.nftOwnerPartImg {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: rgba(245, 245, 247, 0.12);
  background-size: cover;

  @include mobile {
    width: 63px;
    height: 63px;
  }
}

.nftOwnersInfoTextPart {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 18px;
  color: #f5f5f7;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 16px;
  opacity: 0.6;
}

.nftInfoPart {
  display: grid;
  width: 592px;
  margin-top: 4px;
  column-gap: 32px;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;

  @include mobile {
    width: unset;
    margin-top: 14px;
    gap: 12px;
    grid-template-columns: 1fr;
  }
}

.nftInfoPartRecord {
  display: grid;
  gap: 43px;
  grid-template-columns: 80px 176px;
}

.nftInfoPartKey,
.nftInfoPartValue {
  color: #f5f5f7;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 16px;
}

.nftInfoPartKey {
  opacity: 0.8;
}

.nftButtonsHolder {
  display: flex;
  margin-left: auto;
  gap: 16px;

  @include mobile {
    position: absolute;
    left: calc(100% - 48px);
    flex-direction: column-reverse;
    margin-left: unset;
    gap: 0;
  }
}

.nftFav {
  position: absolute;
  left: calc(100% - 48px);

  @include mobile {
    top: 96px;
  }
}

.nftRefreshButton {
  @include mobile {
    display: none;
  }
}

.nftOwnershipHistory {
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  @include mobile {
    margin-top: 40px;
  }
}

.nftOwnershipHistoryLabel {
  margin-bottom: 32px;
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 16px;

  @include mobile {
    margin-bottom: 20px;
  }
}

.nftOwnershipHistoryRecord {
  display: grid;
  align-items: center;
  padding: 22px 34px;
  border-radius: 5px;
  background: rgba(245, 245, 247, 0.02);
  font-size: 14px;
  font-weight: 500;
  gap: 40px;
  grid-template-columns: 36px 1fr 100px 100px 100px;
  line-height: 16px;

  @include mobile {
    padding: 16px 20px;
    gap: 12px;

    //grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 40px auto;

    row-gap: 8px;
  }
}

.nftOwnershipOwnerImg {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-size: cover;

  @include mobile {
    width: 44px;
    height: 44px;
  }
}

.nftOwnershipDate {
  @include mobile {
    grid-column-end: 1;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-row-start: 3;
  }
}

.nftOwnershipType {
  @include mobile {
    white-space: nowrap;
  }
}

.nftOwnershipTime {
  @include mobile {
    margin-left: auto;
    grid-column-end: 2;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-row-start: 3;
  }
}
