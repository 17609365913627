@import 'src/styles/utils';

.box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  grid-gap: 32px;
}

.majorWallet {
  display: flex;
  flex: 0 1 384px;
  flex-direction: column;
  padding: 28px 36px 20px 44px;
  border-radius: 5px;
  backdrop-filter: blur(65px);
  background: linear-gradient(106.36deg, rgb(23 104 229 / 50%) -1.83%, rgb(23 104 229 / 0%) 115.25%);
  box-shadow: inset 5px 5px 10px rgb(23 104 229 / 10%), inset -5px -5px 10px rgb(23 104 229 / 10%);
  filter: drop-shadow(20px 30px 110px rgb(0 0 0 / 12%));

  @include mobile {
    height: 145px;
    flex-grow: 1;
    justify-content: space-around;
    padding: 20px 20px 10px 32px;
  }
}

.info {
  margin-bottom: 12px;
  color: #f5f5f7;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  @include mobile {
    font-size: 14.1848px;
    line-height: 19px;
  }
}

.total {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #f5f5f7;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;

  @include mobile {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
  }
}

.emptyTitle {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.icon {
  margin-right: 12px;

  @include mobile {
    width: 26px;
    height: 26px;
  }
}

.addressButton {
  font-size: 20px;
  letter-spacing: 0.002em;
  line-height: 24px;

  @include mobile {
    font-size: 16px;
    line-height: 20px;
  }
}

.copyIcon {
  margin-left: 24px;

  @include mobile {
    margin-left: 4px;
  }
}

.cards {
  display: grid;
  flex: 0 1 284px;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 84px 84px;

  @include mobile {
    flex-grow: 1;
  }

  > :first-child {
    grid-column: span 3;
  }

  > * {
    grid-column: span 1;
  }
}
