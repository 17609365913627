@import '../../../styles/variables';
@import '../../../styles/mixins';

.dappsImgHolder {
  overflow: hidden;
  width: 202px;
  height: 180px;
  border: 1px solid #6b798f;
  border-radius: 5px;

  @include mobile {
    width: unset;
    height: unset;
  }
}

.dappsName {
  margin-top: 16px;
  margin-bottom: 8px;
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 16px;

  @include mobile {
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 14px;
  }
}

.dappsImg {
  width: 100%;
  height: 100%;
}

.dappsItem {
  position: relative;
  width: 210px;
  flex-grow: 0;

  @include mobile {
    width: unset;
  }

  &:hover {
    .dappsImgHolder {
      @include border-gradient(linear-gradient(90deg, #1768E5 0%, #00F0FF 100%));
      border: unset;
      background: #121923;
    }

    .dappsName {
      @include text-gradient(linear-gradient(90deg, #1768E5 0%, #00F0FF 100%));
    }

    .dappsImg {
      transform: scale(1.1);
    }

    .dappsFav {
      z-index: 250;
    }
  }
}

.dappsListWrapper {
  display: grid;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  gap: 28px;
  grid-template-columns: repeat(auto-fill, 202px);

  @include mobile {
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    row-gap: 28px;
  };
}

.dappsGenre {
  color: #f5f5f7;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.8;

  @include mobile {
    font-size: 12px;
    line-height: 14px;
  }
}

.dappsFav {
  position: absolute;
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: rgba(245, 245, 247, 0.06);

  @include mobile {
    top: 8px;
    right: 8px;
  }

  &:hover {
    background: rgba(245, 245, 247, 0.12);
  }
}
