@import 'src/styles/utils';

.panel {
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 40px;

  @include mobile {
    flex-direction: column;
    padding: 32px 15px 28px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  color: #f5f5f7;

  @include mobile {
    margin-bottom: 32px;
  }
}

.title {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.balance {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
}

.emptyTitle {
  color: #f5f5f7;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  opacity: 0.6;
}

.icon {
  margin-right: 12px;
}

.linksGroup {
  display: flex;
  grid-gap: 16px;

  @include mobile {
    justify-content: space-between;
  }
}

.linksGroup > * {
  width: 90px;
  height: 77px;

  @include mobile {
    flex: 1 1 auto;
  }
}

.tokensList {
  padding: 0 32px;
  margin-top: 40px;

  > :not(:first-child) {
    margin-top: 40px;
  }
}

.noTokens {
  margin: 24px 0;
  text-align: center;
}

.myAssetsTabIndicator {
  height: 2px;
  background: #f5f5f7;
}

.myAssetsTabSelected {
  background: unset;
  background-clip: unset;
  background-clip: unset;
  color: #f5f5f7 !important;
  -webkit-text-fill-color: unset;
  text-fill-color: unset;
}

.myAssetsTabsRoot {
  padding: 0 20px;
}

.myAssetsAddAssetsButton {
  margin-right: 32px;
  margin-bottom: 24px;
  margin-left: auto;
}
