@import '../../styles/mixins';

.underConstructionContent {
  width: 380px;
  padding: 42px 58px;
  background: #121923;

  @include mobile {
    width: 100vw;
    max-width: unset !important;
    padding-right: 20px;
    padding-left: 20px;
  };
}

.underConstructionTitleHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
}

.underConstructionTitle {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 20px;
  text-align: center;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.toHomeButton {
  @include the-power-color;
  width: 178px;
  height: 48px;
  border-radius: 5px;

  margin-top: 16px;
  margin-bottom: 20px;
  color: #f5f5f7;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-transform: unset;
}
