@import '../.././styles/mixins';

.txResult {
  padding: 32px;
  margin: 0 auto;
  overflow-y: auto;
}

.result {
  display: flex;
  width: 100%;
  max-width: 496px;
  flex-direction: column;
  padding: 20px 24px;
  border-radius: 5px;
  background: #252b35;
  grid-gap: 12px;
}

.resultKey {
  margin-bottom: 8px;
  color: #6b798f;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.resultValue {
  display: flex;
  align-items: center;
  color: #f5f5f7;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.resultValue_success {
  display: flex;
  align-items: center;
  color: #f5f5f7;

  color: #2dac3a;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.resultValue__successIcon {
  margin-left: 8px;
}

.commentLabel {
  margin: 8px 0;
  color: #6b798f;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.comment {
  width: 100%;
  min-height: 120px;
  padding: 12px 16px;
  border-radius: 5px;
  background: #1b222c;
  color: #f5f5f7;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;
}

.icon {
  margin-left: 12px;
}

.columns {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  column-gap: 24px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
  gap: 20px;

  @include mobile {
    flex-direction: column;
    align-content: space-between;
  }
}

.socials {
  display: grid;
  gap: 20px;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
}
