@import '../../../styles/mixins';

.nftCollectionCard {
  overflow: hidden;
  width: 100vw;
}

.nftCollectionCardControls {
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.nftCoverHolder {
  width: 100%;
  height: 180px;
  background-size: cover;

  @include mobile {
    height: 140px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.nftCollectionCardMetricsHolder {
  position: relative;
  top: 75%;
  left: 320px;
  display: flex;
  gap: 40px;
  grid-template-columns: auto auto;

  @include mobile {
    top: 90px;
    left: 10px;
    gap: 8px;
  }
}

.nftCollectionCardMetrics {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  align-items: center;
  color: #f5f5f7;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  @include mobile {
    font-size: 12px;
    line-height: 14px;
  };
}

.nftCollectionCardMetricsDesc {
  margin-bottom: 4px;

  @include mobile {
    white-space: nowrap;
  }
}

.nftSmallCover {
  position: relative;
  top: 35%;
  left: 136px;
  width: 130px;
  height: 116px;
  border: 2px solid rgba(245, 245, 247, 0.12);
  border-radius: 5px;
  filter: drop-shadow(0 0 8px rgba(11, 14, 19, 0.24));

  @include mobile {
    top: 128px;
    left: 10px;
    width: 61px;
    height: 61px;
  }
}

.nftCollectionCardInfoHolder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 138px;
  margin-top: 74px;

  @include mobile {
    padding: 0 10px;
    margin-top: 95px;
  }
}

.nftCollectionCardTitle {
  margin-bottom: 12px;
  color: #f5f5f7;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.nftCollectionCardDesc {
  max-width: 590px;
  color: #f5f5f7;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.nftCollectionCardIconsHolder {
  display: flex;
  align-items: center;

  @include mobile {
    position: absolute;
    top: 168px;
    right: 15px;
  }
}

.nftCollectionCardShowMoreButton {
  margin-top: 12px;
  color: #f5f5f7;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.32;
}

.nftCollectionCardShowMoreButtonLabel {
  margin-right: 14px;
}

.nftCollectionNft {
  width: 176px;
  height: 228px;
  border-radius: 5px;
  backdrop-filter: blur(20px);
  background: rgba(245, 245, 247, 0.08);

  @include mobile {
    width: 100%;
    max-width: 176px;
    background: unset;
  }

  &:hover {
    .nftCollectionNftImg {
      transform: scale(1.1);
    }
  }
}

.nftCollectionNftImgHolder {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.nftCollectionNftImg {
  overflow: hidden;
  width: 176px;
  height: 140px;
  background-size: cover;

  @include mobile {
    width: unset;
    max-width: 176px;
    height: 120px;
  }
}

.nftCollectionNftFav {
  position: absolute;
  top: 9px;
  right: 9px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: rgba(245, 245, 247, 0.06);

  @include mobile {
    top: 8px;
    right: 8px;
  }

  &:hover {
    background: rgba(245, 245, 247, 0.12);
  }
}

.nftCollectionNftInfo {
  display: flex;
  height: 88px;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
}

.nftCollectionNftInfoFirstLine,
.nftCollectionNftInfoSecondLine {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.nftCollectionNftInfoSecondLine {
  align-items: flex-end;
}

.nftCollectionNftNumber {
  color: #f5f5f7;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.nftCollectionNftCount {
  color: #f5f5f7;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: right;
}

.nftCollectionNftCountLabel {
  margin-right: 4px;
  font-weight: 300;
}

.nftCollectionNftPriceHolder {
  display: flex;
  flex-direction: column;
  color: #f5f5f7;
}

.nftCollectionNftPriceLabel {
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
}

.nftCollectionNftPrice {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.nftCollectionNftPriceChangeHolder {
  display: flex;
  color: #2dac3a;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: right;

  &_negative {
    color: #ac2d2d;
  }
}

.nftCollectionNftPriceChange {
  margin: 0 4px;
}

.nftCollectionNftHolder {
  display: flex;
  flex-direction: column;
  padding: 0 136px 97px;
  margin-top: 60px;

  @include mobile {
    padding: 0 10px 97px;
    margin-top: 52px;
  }
}

.nftCollectionNftHolderTitle {
  color: #f5f5f7;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.nftCollectionNftDivider {
  width: 100%;
  height: 2px;
  border-radius: 5px 5px 0 0;
  margin-top: 20px;
  margin-bottom: 32px;
  background: #f5f5f7;
  opacity: 0.12;

  @include mobile {
    margin-top: 8px;
    margin-bottom: 32px;
  }
}

.nftCollectionNftPagination {
  justify-content: flex-end;
  margin-top: 28px;

  @include mobile {
    margin-top: 0;
  }
}

.nftCollectionNftListWrapper {
  display: grid;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  gap: 32px;
  grid-template-columns: repeat(auto-fill, 176px);

  @include mobile {
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  };
}
