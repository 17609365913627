@import '../../styles/variables';
@import '../../styles/mixins';

.modalContent {
  max-width: 384px !important;
  padding: 64px 50px 56px;
  background: #121923;

  @include mobile {
    max-width: unset !important;
    padding: 42px 20px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  margin: 20px 0 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.subTitle {
  margin-bottom: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.passwordInput {
  width: 280px;
  height: 48px;
  margin-top: 26px;
  background: #1d242e;

  @include mobile {
    width: 100%;
  }
}

.button {
  margin-top: 32px;
}
