@import '../../styles/mixins';
@import '../../styles/variables';

.bar {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 32px;
  background: inherit;

  @include mobile {
    padding: 8px 15px;
  }
}

.deep,
.shallow {
  position: sticky;
  z-index: 300;
  top: 0;
}

.shallow {
  @include mobile {
    position: static;
    height: $top-bar-mobile;
    padding: 24px 15px 12px;
  }
}

.childrenInsideBar {
  flex: 1 1 auto;

  @include mobile {
    display: none;
  }
}

.childrenOutsideBar {
  display: none;

  @include mobile {
    position: sticky;
    z-index: 100000;
    top: 0;
    display: block;
    background-color: #111923;
  }
}

.accountHolder {
  display: none;

  @include mobile {
    display: block;
  }
}

.controlsSet {
  display: flex;
  align-items: center;
}

.mlA {
  margin-left: auto;
}

.title {
  position: absolute;
  left: 50%;
  color: #f5f5f7;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  transform: translateX(-50%);
}
