@import 'src/styles/variables';
@import 'src/styles/mixins';

.button {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.medium {
  min-width: 190px;
  height: 48px;
}

.large {
  min-width: 290px;
  height: 56px;
}

.fullWidth {
  width: 100%;
  flex-shrink: 1;
}

.text {
  display: flex;
  align-items: center;
  margin: 0 4px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.filled {
  background: linear-gradient(90deg, #1768e5 50.44%, #00f0ff 129.31%);

  .text {
    color: #f5f5f7;
  }

  &:hover {
    background: linear-gradient(90deg, #1768e5 0%, #00f0ff 100%);
  }

  &:active {
    background: linear-gradient(90deg, rgba(23, 104, 229, 0.8) 0%, rgba(0, 240, 255, 0.8) 100%);
  }

  &:disabled {
    background: #26354d;
    pointer-events: none;

    .text {
      color: #79789d;
    }
  }
}

@include icon-colors('.filled', #f5f5f7, #f5f5f7, #f5f5f7);

.outlined {
  @include border-gradient(linear-gradient(90deg, #1768e5 50.44%, #00f0ff 129.31%));
  background: #121923;

  .text {
    @include text-gradient(linear-gradient(90deg, #1768e5 50.44%, #00f0ff 129.31%));
  }

  &:hover {
    @include border-gradient(linear-gradient(90deg, #1768e5 0%, #00f0ff 100%));

    .text {
      @include text-gradient(linear-gradient(90deg, #1768e5 0%, #00f0ff 100%));
    }
  }

  &:active {
    @include border-gradient(linear-gradient(90deg, rgba(23, 104, 229, 0.8) 0%, rgba(0, 240, 255, 0.8) 100%));

    .text {
      @include text-gradient(linear-gradient(90deg, rgba(23, 104, 229, 0.8) 0%, rgba(0, 240, 255, 0.8) 100%));
    }
  }

  &:disabled {
    @include border-gradient(#26354d);
    background: #26354d;
    pointer-events: none;

    .text {
      @include text-gradient(#79789d);
    }
  }
}

@include icon-colors('.outlined', url('#default'));
