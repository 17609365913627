@import '../../styles/variables';
@import '../../styles/mixins';

.modal {
  max-width: unset;
  border-radius: $unit * 2.5;

  @media screen and (max-width: $tablet) {
    width: 100vw;
    min-width: unset;
    height: 100vh;
    max-height: unset;
    margin: 0 !important;
  }
}

.modalRoot {
  z-index: 13000;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalLoaderText {
  margin-top: 80px;
  text-align: center;
  white-space: pre-line;
}

.modalLoader {
  display: flex;
  min-width: 384px;
  min-height: 440px;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background: rgb(255 255 255 / 2%);

  @include mobile {
    width: 100vw;
    min-width: unset;
    height: 100vh;
    min-height: unset;
  }
}

.alwaysShowCloseIcon {
  display: flex !important;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;

  @include mobile {
    display: block;
  }
}
