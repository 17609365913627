@import 'src/styles/utils';

.content {
  flex: 1 1 auto;
  padding: 0 32px;
}

.accountHolder {
  display: none;

  @include mobile {
    display: block;
  }
}
