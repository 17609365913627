.root {
  width: max-content;
  min-width: max-content;
  height: 48px;
  padding: 16px 24px;
  border: 1px dashed #6b798f;
  border-radius: 5px;
  color: #6b798f;
  text-transform: none;
}
