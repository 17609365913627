@import '../../../styles/mixins';

.dappsCard {
  overflow: hidden;
  width: 100vw;
}

.dappsCoverHolder {
  width: 100%;
  height: 450px;
  background-size: cover;

  @include mobile {
    height: 140px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.dappsCardControls {
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.dappsCardBackButton {
  @include mobile {
    visibility: hidden;
  }
}

.dappsCardGenreHolder {
  position: relative;
  top: 90%;
  left: 320px;
  display: flex;
  gap: 20px;
  grid-template-columns: auto auto;

  @include mobile {
    top: 100px;
    left: 10px;
    gap: 16px;
  }
}

.dappsCardGenre {
  flex-grow: 0;
  color: #f5f5f7;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.dappsCardSmallCover {
  position: relative;
  top: 75%;
  left: 136px;
  width: 130px;
  height: 116px;
  border: 2px solid rgba(245, 245, 247, 0.12);
  border-radius: 5px;
  filter: drop-shadow(0 0 8px rgba(11, 14, 19, 0.24));

  @include mobile {
    top: 136px;
    left: 10px;
    width: 61px;
    height: 61px;
  }
}

.dappsCardTitle {
  margin-bottom: 12px;
  color: #f5f5f7;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.dappsCardInfoHolder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 136px;
  margin-top: 54px;

  @include mobile {
    padding: 0 10px;
    margin-top: 95px;
  }
}

.dappsCardShowMoreButton {
  margin-top: 12px;
  color: #f5f5f7;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  opacity: 0.32;
}

.dappsCardShowMoreButtonLabel {
  margin-right: 14px;
}

.dappsCardIconsHolder {
  display: flex;
  align-items: center;

  @include mobile {
    position: absolute;
    top: 168px;
    right: 15px;
  }
}

.dappsCardDesc {
  max-width: 592px;
  color: rgba(245, 245, 247, 0.6);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.nftHolder {
  display: flex;
  flex-direction: column;
  padding: 0 136px 97px;
  margin-top: 60px;

  @include mobile {
    padding: 0 10px 97px;
    margin-top: 52px;
  }
}

.nftHolderTitle {
  color: #f5f5f7;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.nftDivider {
  width: 100%;
  height: 2px;
  border-radius: 5px 5px 0 0;
  margin-top: 20px;
  margin-bottom: 32px;
  background: #f5f5f7;
  opacity: 0.12;

  @include mobile {
    margin-top: 8px;
    margin-bottom: 32px;
  }
}

.dappsNftListWrapper {
  display: grid;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  gap: 32px;
  grid-template-columns: repeat(auto-fill, 176px);

  @include mobile {
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  };
}

.dappsNftImg {
  overflow: hidden;
  width: 176px;
  height: 140px;
  background-size: cover;

  @include mobile {
    width: unset;
    max-width: 176px;
    height: 120px;
  }
}

.dappsNftImgHolder {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dappsNftPagination {
  justify-content: flex-end;
  margin-top: 28px;

  @include mobile {
    margin-top: 0;
  }
}

.dappsNft {
  width: 176px;
  height: 228px;
  border-radius: 5px;
  backdrop-filter: blur(20px);
  background: rgba(245, 245, 247, 0.08);

  @include mobile {
    width: 100%;
    max-width: 176px;
    height: 204px;
    background: unset;
  }

  &:hover {
    .dappsNftImg {
      transform: scale(1.1);
    }
  }
}

.dappsNftInfo {
  display: flex;
  height: 88px;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;

  @include mobile {
    padding: 8px;
  }
}

.dappsNftInfoFirstLine,
.dappsNftInfoSecondLine {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.dappsNftInfoSecondLine {
  align-items: flex-end;
}

.dappsCardNftNumber {
  color: #f5f5f7;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.dappsNftCount {
  color: #f5f5f7;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: right;
}

.dappsCardNftCountLabel {
  margin-right: 4px;
  font-weight: 300;
}

.dappsNftPriceHolder {
  display: flex;
  flex-direction: column;
  color: #f5f5f7;
}

.dappsNftPriceLabel {
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
}

.dappsNftPrice {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.dappsNftPriceChangeHolder {
  display: flex;
  color: #2dac3a;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: right;

  &_negative {
    color: #ac2d2d;
  }
}

.dappsNftPriceChange {
  margin: 0 4px;
}

.dappsNftFav {
  position: absolute;
  top: 9px;
  right: 9px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: rgba(245, 245, 247, 0.06);

  @include mobile {
    top: 8px;
    right: 8px;
  }

  &:hover {
    background: rgba(245, 245, 247, 0.12);
  }
}

.dappsButton {
  width: 240px;
  height: 48px;

  @include mobile {
    width: 100%;
  }
}

.dappsButtonHolder {
  display: flex;
  padding: 0 136px;
  margin-top: 36px;
  gap: 20px;

  @include mobile {
    width: 100%;
    flex-direction: column;
    padding: 0 10px;
  }
}


